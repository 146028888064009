<template>
  <base-section id="one-view-features">
    <base-section-heading title="OneView Features"> </base-section-heading>

    <v-container>
      <v-row>
        <v-col v-for="(feature, i) in features" :key="i" cols="12" md="6">
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
            customText="Features"
          >
            -----
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "OneViewFeatures",

  data: () => ({
    features: [
      {
        title: "Single Source of truth",
        image: "data-modelling",
        text: "Achieve consistency and accuracy with a centralized app repository. Provide up-to-date information for efficient decision-making and collaboration across apps, ensuring a reliable source of truth for all users.",
      },
      {
        title: "SSO integration",
        image: "profile",
        text: "Integrate Microsoft's Single Sign-On (SSO) for seamless and secure user authentication. Log in once with Microsoft credentials to streamline user experience, boost productivity, and access interconnected apps effortlessly.",
      },
      {
        title: "Independent deployment of apps",
        image: "deployment",
        text: "Empower your organization with independent app deployment flexibility. Update, modify, or add apps without ecosystem impact. Swiftly introduce features, enhance agility, and maintain cohesive user experience for seamless productivity.",
      },
      {
        title: "Access control using Azure",
        image: "azure",
        text: "Utilize Microsoft Azure capabilities for secure user access management. Seamless integration with Power BI web service handles precise permissions, ensuring robust data governance and integrity across the software ecosystem.",
      },
      {
        title: "Minimize browser tabs",
        image: "web-page",
        text: "Streamline your workflow with the micro-UI interface. Centralize essential apps, enhance productivity, and enjoy a clutter-free workspace. Effortlessly switch between tasks, maintaining app context and state, for a seamless and organized experience.",
      },
      {
        title: "Search Engine to access all resources",
        image: "searching",
        text: "Experience efficient information retrieval with our robust Search Engine. Unified search across integrated apps ensures quick access to relevant data, enhancing user efficiency and streamlining the search experience seamlessly.",
      },
      {
        title: "Configure Apps & Power BI reports",
        image: "device",
        text: "Administer with ease, and effortlessly control app configuration and Power BI setup. Manage access, tailor app settings, and fine-tune reports with user-friendly tools. Customize experiences, reduce complexity, and enhance system administration effectively.",
      },
      {
        title: "Consolidation of Power BI reports",
        image: "powerbi",
        text: "Effortlessly unify Power BI reports. Consolidate reports for holistic data insights, streamlined collaboration, and informed decision-making. Simplified access and management ensure a cohesive view across reporting sources, enhancing overall efficiency.",
      },
    ],
  }),
};
</script>
